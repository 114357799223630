<template>
	<div>
		<!-- <h3>{{ resultMsg }}</h3> -->
		<!-- <h4>주문번호 : {{ orderId }}</h4> -->
		<h3>결제 완료</h3>
		<button @click="closePopup()">창 닫기</button>
	</div>
</template>

<script>
export default {
	name: 'FctsPaymentsSuccess',

	data() {
		return {
			resultMsg: '',
			orderId: '',
		};
	},
	mounted() {
		this.resultMsg = this.$route.query.resultMsg;
		this.orderId = this.$route.query.orderId;
	},

	methods: {
		closePopup() {
			window.close();
		},
	},
};
</script>

<style></style>
